import request from '@/utils/request'


// 查询搜索发现列表
export function listDiscover(query) {
    return request({
        url: '/search/search-discover/list',
        method: 'get',
        params: query
    })
}

// 查询搜索发现分页
export function pageDiscover(query) {
    return request({
        url: '/search/search-discover/page',
        method: 'get',
        params: query
    })
}

// 查询搜索发现详细
export function getDiscover(data) {
    return request({
        url: '/search/search-discover/detail',
        method: 'get',
        params: data
    })
}

// 新增搜索发现
export function addDiscover(data) {
    return request({
        url: '/search/search-discover/add',
        method: 'post',
        data: data
    })
}

// 修改搜索发现
export function updateDiscover(data) {
    return request({
        url: '/search/search-discover/edit',
        method: 'post',
        data: data
    })
}

// 删除搜索发现
export function delDiscover(data) {
    return request({
        url: '/search/search-discover/delete',
        method: 'post',
        data: data
    })
}
